<template>
    <div class="q-pa-md">
        <q-card class="my-card">
            <q-card-section>
                <div class="text-h6">Создать</div>
            </q-card-section>
            <q-separator />
            <q-form @submit="onSubmit">
            <q-card-section>
                <div class="column q-gutter-y-md1">
                    <br>
                    <div class="row q-col-gutter-md">
                        <q-select outlined v-model="form.type" :options="types" label="Тип дела" class="col-grow col-md-4" dense :rules="[selectRule]"/>
                        <q-select
                            outlined
                            dense
                            v-model="form.author"
                            use-input
                            hide-selected
                            fill-input
                            input-debounce="0"
                            :options="authors"
                            @filter="filterAuthors"
                            label="Автор"
                            class="col-12 col-md-4"
                            :rules="[selectRule]"
                            emit-value
                            map-options
                        >
                            <template v-slot:no-option>
                                <q-item>
                                    <q-item-section class="text-grey">
                                    Не надено
                                    </q-item-section>
                                </q-item>
                            </template>
                            <template v-slot:append>
                                <q-btn v-if=" form.author === null" round dense flat icon="add" @click.stop="openAuthorForm = true" />
                                <q-icon v-if="form.author !== null" class="cursor-pointer" name="clear"  @click.stop="form.author = null"  />
                            </template>
                        </q-select>
                            <q-select
                            outlined
                            dense
                            v-model="form.violator"
                            use-input
                            hide-selected
                            fill-input
                            input-debounce="0"
                            :options="violators"
                            @filter="filterViolators"
                            label="Нарушитель"
                            class="col-12 col-md-4"
                            :rules="[selectRule]"
                            emit-value
                            map-options
                        >
                            <template v-slot:no-option>
                                <q-item>
                                    <q-item-section class="text-grey">
                                    Не надено
                                    </q-item-section>
                                </q-item>
                            </template>
                            <template v-slot:append>
                                <q-btn v-if="form.violator === null" round dense flat icon="add" @click.stop="openViolatorForm = true" />
                                <q-icon v-if="form.violator !== null" class="cursor-pointer" name="clear"  @click.stop="form.violator = null"  />
                            </template>
                        </q-select>
                    </div>
                    <br>
                    <div class="row q-col-gutter-md">
                         <q-input outlined dense v-model="form.date" mask="##.##.####" label="Дата" class="col-grow col-md-4" required hint="">
                            <template v-slot:append>
                                <q-icon name="event" class="cursor-pointer">
                                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                        <q-date v-model="form.date" mask="DD.MM.YYYY">
                                            <div class="row items-center justify-end">
                                                <q-btn v-close-popup label="Ok" color="primary" flat />
                                            </div>
                                        </q-date>
                                    </q-popup-proxy>
                                </q-icon>
                            </template>
                        </q-input>
                        <q-select outlined v-model="form.region" :options="regions" label="Регион" class="col-grow col-md-4" @input="form.city = null" dense :rules="[selectRule]"/>
                        <q-select outlined v-model="form.city" :options="citys" label="Город" class="col-grow col-md-4" dense :rules="[selectRule]"/>
                       
                    </div>
                    <violations/>
                    <q-banner v-if="this.$store.state.cases.violationsError" inline-actions class="text-white bg-red">
                        Не добавленны нарушения
                    </q-banner>
                   
                </div>
            </q-card-section>   
             <q-card-section class="q-mt-none">
                <q-btn type="submit" class="bg-primary text-white" label="Создать"/>
            </q-card-section>
            </q-form>
        </q-card>
        <author-form :openDialog="openAuthorForm" @close="openAuthorForm = false"/>
        <violator-form :openDialog="openViolatorForm" @close="openViolatorForm = false"/>
    </div>
</template>

<script>
import Violations from '@/components/cases/violations.vue'
import AuthorForm from '@/components/authors/form.vue'
import ViolatorForm from '@/components/violators/form.vue'
export default {
  components: {  Violations, AuthorForm, ViolatorForm },
    created(){
        this.$store.commit('cases/SET_EMPTY_ITEM')
        this.$store.dispatch('regions/fetchRegions')
        this.form.violator = this.$store.state.cases.item.violator
        this.form.author = this.$store.state.cases.item.author
    },
    data () {
        return {
            form:{
                type:null,
                date:new Date().toLocaleDateString(),
                city: null,
                region:'',
                violator: null,
                author: null,
            },
            authors: [],
            violators: [],
            openAuthorForm: false,
            openViolatorForm: false,
            
        }
    },
    watch: {
        'form.violator'(val){
            if(val !== null){
                this.$store.commit('cases/SET_VIOLATOR', {id:val.id, label:val.name})
            }else{
                this.$store.commit('cases/SET_VIOLATOR', null)
            }
        }
    },
    computed: {
        regions(){
            return this.$store.getters['regions/list']
        },
        types(){
            return this.$store.state.cases.types
        },
        citys(){
            if(this.form.region.id){
                return this.$store.getters['regions/citys'](this.form.region.id)
            }
            return []
        },
       
    },
    methods: {
        async filterAuthors(val, update, abort){
                if (val.length < 2) {
                    abort()
                    return
                }
                this.authors = await this.$store.dispatch('authors/searchByName', val.toLowerCase())
                update()
                return
        },
        async filterViolators(val, update, abort){
                if (val.length < 2) {
                    abort()
                    return
                }
                this.violators = await this.$store.dispatch('violators/searchByName', val.toLowerCase())
                update()
                return
        },
        onSubmit(){
            if(this.$store.state.cases.violations.length < 1){
                this.$store.commit('cases/SET_VIOLATIONS_ERROR', true)
                return false
            }
           this.$store.dispatch('cases/create', this.form)
           this.$router.push('/cases')
        },
        selectRule (val) {
            if (val === null) {
                return ''        
            }
        }
    }
}
</script>